
import axios from "axios";
import {getTokenLocal} from "../utils/Session"

const API_URL = process.env.REACT_APP_API_URL;

const topicAPI = axios.create({
    baseURL: API_URL,
    // headers:{
    //     common:{
    //         Authorization: `Bearer ${getTokenLocal()}`
    //     }
    // }
  });


export const getTopicsAPI = async ()=> {
  topicAPI.defaults.headers.common['Authorization'] = `Bearer ${getTokenLocal()}`
  const res = await topicAPI.get(`/topics`);
  return res;
} 


export const postTopicsAPI = async (data)=>{
  topicAPI.defaults.headers.common['Authorization'] = `Bearer ${getTokenLocal()}`
  const res = await topicAPI.post("/add-topic",data,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
});
  return res;
} 