import React from 'react'
import './CustomTextField.css'
import { MdGpsFixed } from 'react-icons/md';
import { useFormContext } from 'react-hook-form';

function CustomHiddenField({ label, placeholder, name, type, icon, classNamePro, showGps, validation, readOnly, value }) {

  const { register, formState } = useFormContext();
  const { errors } = formState;


  return (
    <>
      <span className='color-999999'>{icon}</span>
      <input {...register(name, validation)} type={type} value={value} className="form-control skranji-regular color-999999" placeholder={placeholder} name={name} readOnly={readOnly} />
    </>
  )
}

// CustomTextField.defaultProps = {
//   isRequired:true,
// };

export default CustomHiddenField