import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()


export const userAtom = atom({
    key: 'user',
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const tokenAtom = atom({
    key: 'token',
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const roleAtom = atom({
    key: 'role',
    default: null,
    effects_UNSTABLE: [persistAtom],
});