import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import CustomSelectField from '../../components/globals/CustomSelectField';
import CustomTextAreaField from '../../components/globals/CustomTextAreaField';
import { apiGetAuth, apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate, useParams } from 'react-router-dom';

const EditBadges = () => {
    const methods = useForm();

    const { handleSubmit, reset, watch, setValue } = methods;
    const [activeTab, setActiveTab] = useState('ocean');
    const [badge, setBadgeData] = useState(0);
    const [submittedData, setSubmittedData] = useState(null);
    const navigate = useNavigate();
    const { badgeId } = useParams();

    const [oceans, setOceans] = useState([]);

    const onSubmit = (data) => {
        const formData = new FormData();

        let goalType;
        switch (activeTab) {
            case 'ocean':
                goalType = 'ocean';
                break;
            case 'quiz':
                goalType = 'quiz';
                break;
            case 'challenge_quest':
                goalType = 'challenge_quest';
                break;
            case 'streak':
                goalType = 'streak';
                break;
            case 'quest':
                goalType = 'quest';
                break;
            default:
                goalType = '';
        }

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        formData.append('goal_type', goalType);

        const avatarLockInput = document.querySelector('input[name="avatar_lock"]');
        const avatarInput = document.querySelector('input[name="avatar"]');

        if (avatarLockInput.files[0]) {
            formData.append('avatar_lock', avatarLockInput.files[0]);
        }
        if (avatarInput.files[0]) {
            formData.append('avatar', avatarInput.files[0]);
        }

        apiPost(
            `${ENDPOINTS.AdminEditBadges}/${badgeId}`,
            formData,
            (res) => {
                setSubmittedData(data);
                navigate(`/super-admin/badges`);
            },
            (err) => {
                console.log(err, "Error uploading the data");
            },
        );
    };

    const getOceans = () => {
        apiGetAuth(
            ENDPOINTS.AdminOceansWithoutPaginated,
            (res) => {
                setOceans(res);
            },
            (err) => {
                console.log(err, "Error fetching oceans");
            }
        );
    };

    const fetchBadgeData = () => {
        apiGetAuth(
            `${ENDPOINTS.AdminBadgeById}/${badgeId}`,
            (res) => {
                setBadgeData(res?.badge);
                setActiveTab(res?.badge?.goal_type);
            },
            (err) => {
                console.log(err, "Error fetching badge data");
            }
        );
    };

    useEffect(() => {
        if (badge) {
            methods.setValue("goal_name", badge.goal_name);
            methods.setValue("source_id", badge.source_id);
            methods.setValue("goal_type", badge.goal_type);
            methods.setValue("level", badge.level);
            methods.setValue("number_of_oceans", badge.number_of_oceans);
            methods.setValue("number_of_stages", badge.number_of_stages);
            methods.setValue("coins_reward", badge.coins_reward);
            methods.setValue("avatar", badge.avatar);
            methods.setValue("avatar_lock", badge.avatar_lock);
            methods.setValue("goal_description", badge.description);
            methods.setValue("challenge_points", badge.challenge_points || '');
            methods.setValue("number_of_days", badge.number_of_days || '');
            methods.setValue("number_of_quests", badge.number_of_quests || '');
        }
    }, [badge, methods]);

    useEffect(() => {
        getOceans();
        fetchBadgeData();
    }, [badgeId]);

    return (
        <div className='mb-4 mt-2 px-4'>
            <h1>Edit Badge</h1>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className="tab-pane active">
                            <div className='mb-4 mt-2 px-4 bg-light rounded shadow-sm p-3'>

                                <CustomTextField
                                    label="Goal Name"
                                    placeholder="Enter Goal Name"
                                    name="goal_name"
                                    type="text"
                                    classNamePro="stage-field mb-3"
                                    isRequired={true}
                                    validation={{ required: "Goal name is required" }}
                                />

                                {badge.goal_type === "ocean" && (
                                    <CustomSelectField
                                        label="Select Ocean"
                                        name="source_id"
                                        options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                                        classNamePro="ocean-field mb-3"
                                        validation={{ required: 'Ocean is required' }}
                                    />
                                )}

                                {(badge.goal_type === "quiz" || badge.goal_type === "quest") && (
                                    <CustomSelectField
                                        label="Level"
                                        name="level"
                                        options={[
                                            { value: 'Easy', label: 'Easy' },
                                            { value: 'Intermediate', label: 'Intermediate' },
                                            { value: 'Advance', label: 'Advance' }
                                        ]}
                                        classNamePro="stage-field mb-3"
                                        validation={{ required: "Level is required" }}
                                    />
                                )}

                                {(badge.goal_type === "ocean" || badge.goal_type === "challenge_quest") && (
                                    <CustomSelectField
                                        label="Level"
                                        name="level"
                                        options={[
                                            { value: 'Level 1', label: 'Level 1' },
                                            { value: 'Level 2', label: 'Level 2' },
                                            { value: 'Level 3', label: 'Level 3' },
                                            { value: 'Level 4', label: 'Level 4' },
                                            { value: 'Level 5', label: 'Level 5' },
                                            { value: 'Level 6', label: 'Level 6' },
                                            { value: 'Level 7', label: 'Level 7' },
                                            { value: 'Level 8', label: 'Level 8' },
                                        ]}
                                        classNamePro="stage-field mb-3"
                                        validation={{ required: "Level is required" }}
                                    />
                                )}

                                {badge.goal_type === "ocean" && (
                                    <CustomTextField
                                        label="Number of Stages"
                                        placeholder="Enter Number of Stages"
                                        name="number_of_stages"
                                        type="number"
                                        classNamePro="stage-field mb-3"
                                    />
                                )}

                                {badge.goal_type === "quest" && (
                                    <CustomTextField
                                        label="Number Of Quests"
                                        placeholder="Enter Number Of Quests"
                                        name="number_of_quests"
                                        type="number"
                                        classNamePro="stage-field mb-3"
                                    />
                                )}

                                {badge.goal_type === "quiz" && (
                                    <CustomTextField
                                        label="Number of Oceans"
                                        placeholder="Enter Number of Oceans"
                                        name="number_of_oceans"
                                        type="number"
                                        classNamePro="stage-field mb-3"
                                    />
                                )}

                                {badge.goal_type === "challenge_quest" && (
                                    <CustomTextField
                                        label="Challenge Points"
                                        placeholder="Enter Challenge Points"
                                        name="challenge_points"
                                        type="number"
                                        classNamePro="stage-field mb-3"
                                    />
                                )}

                                {badge.goal_type === "streak" && (
                                    <CustomTextField
                                        label="Number Of Days"
                                        placeholder="Enter Number Of Days"
                                        name="number_of_days"
                                        type="number"
                                        classNamePro="stage-field mb-3"
                                    />
                                )}

                                <CustomTextField
                                    label="Coins Reward"
                                    placeholder="Enter Coins Reward"
                                    name="coins_reward"
                                    type="number"
                                    classNamePro="stage-field mb-3"
                                    isRequired={true}
                                    validation={{ required: "Coins reward is required" }}
                                />

                                <CustomTextField
                                    label="Badge Unlock"
                                    placeholder="Upload Image"
                                    name="avatar"
                                    type="file"
                                    classNamePro="ocean-field image-file-ocean mb-3"
                                    isRequired={true}
                                    validation={{
                                        required: "This field is required",
                                    }}
                                />

                                <CustomTextField
                                    label="Badge Lock"
                                    placeholder="Upload Image"
                                    name="avatar_lock"
                                    type="file"
                                    classNamePro="ocean-field image-file-ocean mb-3"
                                    isRequired={true}
                                    validation={{
                                        required: "This field is required",
                                    }}
                                />

                                <CustomTextAreaField
                                    label="Description"
                                    placeholder="Enter Description"
                                    name="goal_description"
                                    classNamePro="stage-field mb-3"
                                    isRequired={false}
                                    value={methods.watch("goal_description")}
                                    onChange={(data) => methods.setValue("goal_description", data)}
                                />

                                <button type="submit" className="btn btn-primary mt-3">Edit Badge</button>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default EditBadges;