import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import CustomSelectField from '../../components/globals/CustomSelectField';
import CustomTextAreaField from '../../components/globals/CustomTextAreaField';
import CustomHiddenField from '../../components/globals/CustomHiddenField';
import { apiGetAuth, apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate } from 'react-router-dom';

const AddBadges = () => {
    const methods = useForm();
    const { handleSubmit, reset, watch } = methods;
    const [activeTab, setActiveTab] = useState('ocean');
    const [submittedData, setSubmittedData] = useState(null);
    const navigate = useNavigate();
    const [oceans, setOceans] = useState([]);

    const onSubmit = (data) => {
        const formData = new FormData();

        let goalType;
        switch (activeTab) {
            case 'ocean':
                goalType = 'ocean';
                break;
            case 'quiz':
                goalType = 'quiz';
                break;
            case 'challenge_quest':
                goalType = 'challenge_quest';
                break;
            case 'streak':
                goalType = 'streak';
                break;
            case 'quest':
                goalType = 'quest';
                break;
            default:
                goalType = '';
        }

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        formData.append('goal_type', goalType);

        const avatarLockInput = document.querySelector('input[name="avatar_lock"]');
        const avatarInput = document.querySelector('input[name="avatar"]');

        if (avatarLockInput.files[0]) {
            formData.append('avatar_lock', avatarLockInput.files[0]);
        }
        if (avatarInput.files[0]) {
            formData.append('avatar', avatarInput.files[0]);
        }

        apiPost(
            ENDPOINTS.AdminAddBadges,
            formData,
            (res) => {
                setSubmittedData(data);
                reset();
                navigate(`/super-admin/badges`);
            },
            (err) => {
                console.log(err, "Error uploading the data");
            },
        );
    };

    const getOceans = () => {
        apiGetAuth(
            ENDPOINTS.AdminOceansWithoutPaginated,
            (res) => {
                setOceans(res);
            },
            (err) => {
                console.log(err, "Error fetching oceans");
            }
        );
    };

    useEffect(() => {
        getOceans();
    }, []);

    return (
        <div className='mb-4 mt-2 px-4'>
            <h1>Badges</h1>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        {/* Tab Navigation */}
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'ocean' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('ocean')}
                                    type="button"
                                >
                                    Ocean Badges
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'quiz' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('quiz')}
                                    type="button"
                                >
                                    Quiz Badges
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'challenge_quest' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('challenge_quest')}
                                    type="button"
                                >
                                    Challenge Quest
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'streak' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('streak')}
                                    type="button"
                                >
                                    Streak Badges
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'quest' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('quest')}
                                    type="button"
                                >
                                    Quest Badges
                                </button>
                            </li>
                        </ul>

                        {/* Tab Content */}
                        <div className="tab-content mt-3">
                            {activeTab === 'ocean' && (
                                <div className="tab-pane active">
                                    <div className='mb-4 mt-2 px-4 bg-light rounded shadow-sm p-3'>
                                        {/* Input Fields */}
                                        <CustomTextField
                                            label="Goal Name"
                                            placeholder="Enter Goal Name"
                                            name="goal_name"
                                            type="text"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Goal name is required" }}
                                        />

                                        {/* Ocean Dropdown */}
                                        <CustomSelectField
                                            label="Select Ocean"
                                            name="source_id"
                                            options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                                            classNamePro="ocean-field mb-3"
                                            validation={{ required: 'This field is required' }}
                                        />

                                        <CustomHiddenField
                                            name="goal_type"
                                            value="ocean"
                                            type="hidden"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomSelectField
                                            label="Level"
                                            name="level"
                                            options={[
                                                { value: 'Level 1', label: 'Level 1' },
                                                { value: 'Level 2', label: 'Level 2' },
                                                { value: 'Level 3', label: 'Level 3' },
                                                { value: 'Level 4', label: 'Level 4' },
                                                { value: 'Level 5', label: 'Level 5' },
                                                { value: 'Level 6', label: 'Level 6' },
                                                { value: 'Level 7', label: 'Level 7' },
                                                { value: 'Level 8', label: 'Level 8' },
                                            ]}
                                            classNamePro="stage-field mb-3"
                                            validation={{ required: "Level is required" }}
                                        />

                                        <CustomTextField
                                            label="Number of Stages"
                                            placeholder="Enter Number of Stages"
                                            name="number_of_stages"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomTextField
                                            label="Coins Reward"
                                            placeholder="Enter Coins Reward"
                                            name="coins_reward"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Coins reward is required" }}
                                        />

                                        <CustomTextField
                                            label="Badge Unlock"
                                            placeholder="Upload Image"
                                            name="avatar"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextField
                                            label="Badge Lock"
                                            placeholder="Upload Image"
                                            name="avatar_lock"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextAreaField
                                            label="Description"
                                            placeholder="Enter Description"
                                            name="goal_description"
                                            classNamePro="stage-field mb-3"
                                            isRequired={false}
                                            value={methods.watch("goal_description")}
                                            onChange={(data) => methods.setValue("goal_description", data)}
                                        />

                                        <button type="submit" className="btn btn-primary mt-3">Add Badge</button>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'quiz' && (
                                <div className="tab-pane active">
                                    <div className='mb-4 mt-2 px-4 bg-light rounded shadow-sm p-3'>
                                        {/* Input Fields */}
                                        <CustomTextField
                                            label="Goal Name"
                                            placeholder="Enter Goal Name"
                                            name="goal_name"
                                            type="text"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Goal name is required" }}
                                        />

                                        {/* Ocean Dropdown */}
                                        <CustomSelectField
                                            label="Select Ocean"
                                            name="source_id"
                                            options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                                            classNamePro="ocean-field mb-3"
                                            validation={{ required: 'This field is required' }}
                                        />

                                        <CustomHiddenField
                                            name="goal_type"
                                            value="quiz"
                                            type="hidden"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomSelectField
                                            label="Level"
                                            name="level"
                                            options={[
                                                { value: 'Easy', label: 'Easy' },
                                                { value: 'Intermediate', label: 'Intermediate' },
                                                { value: 'Advance', label: 'Advance' }
                                            ]}
                                            classNamePro="stage-field mb-3"
                                            validation={{ required: "Level is required" }}
                                        />

                                        <CustomTextField
                                            label="Number of Oceans"
                                            placeholder="Enter Number of Oceans"
                                            name="number_of_oceans"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomTextField
                                            label="Coins Reward"
                                            placeholder="Enter Coins Reward"
                                            name="coins_reward"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Coins reward is required" }}
                                        />

                                        <CustomTextField
                                            label="Badge Unlock"
                                            placeholder="Upload Image"
                                            name="avatar"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextField
                                            label="Badge Lock"
                                            placeholder="Upload Image"
                                            name="avatar_lock"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextAreaField
                                            label="Description"
                                            placeholder="Enter Description"
                                            name="goal_description"
                                            classNamePro="stage-field mb-3"
                                            isRequired={false}
                                            value={methods.watch("goal_description")}
                                            onChange={(data) => methods.setValue("goal_description", data)}
                                        />

                                        <button type="submit" className="btn btn-primary mt-3">Add Badge</button>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'challenge_quest' && (
                                <div className="tab-pane active">
                                    <div className='mb-4 mt-2 px-4 bg-light rounded shadow-sm p-3'>
                                        {/* Input Fields */}
                                        <CustomTextField
                                            label="Goal Name"
                                            placeholder="Enter Goal Name"
                                            name="goal_name"
                                            type="text"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Goal name is required" }}
                                        />

                                        <CustomHiddenField
                                            name="goal_type"
                                            value="challenge_quest"
                                            type="hidden"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomSelectField
                                            label="Level"
                                            name="level"
                                            options={[
                                                { value: 'Level 1', label: 'Level 1' },
                                                { value: 'Level 2', label: 'Level 2' },
                                                { value: 'Level 3', label: 'Level 3' },
                                                { value: 'Level 4', label: 'Level 4' },
                                                { value: 'Level 5', label: 'Level 5' },
                                                { value: 'Level 6', label: 'Level 6' },
                                                { value: 'Level 7', label: 'Level 7' },
                                                { value: 'Level 8', label: 'Level 8' },
                                            ]}
                                            classNamePro="stage-field mb-3"
                                            validation={{ required: "Level is required" }}
                                        />

                                        <CustomTextField
                                            label="Challenge Points"
                                            placeholder="Enter Challenge Points"
                                            name="challenge_points"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomTextField
                                            label="Coins Reward"
                                            placeholder="Enter Coins Reward"
                                            name="coins_reward"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Coins reward is required" }}
                                        />

                                        <CustomTextField
                                            label="Badge Unlock"
                                            placeholder="Upload Image"
                                            name="avatar"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextField
                                            label="Badge Lock"
                                            placeholder="Upload Image"
                                            name="avatar_lock"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextAreaField
                                            label="Description"
                                            placeholder="Enter Description"
                                            name="goal_description"
                                            classNamePro="stage-field mb-3"
                                            isRequired={false}
                                            value={methods.watch("goal_description")}
                                            onChange={(data) => methods.setValue("goal_description", data)}
                                        />

                                        <button type="submit" className="btn btn-primary mt-3">Add Badge</button>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'streak' && (
                                <div className="tab-pane active">
                                    <div className='mb-4 mt-2 px-4 bg-light rounded shadow-sm p-3'>
                                        {/* Input Fields */}
                                        <CustomTextField
                                            label="Goal Name"
                                            placeholder="Enter Goal Name"
                                            name="goal_name"
                                            type="text"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Goal name is required" }}
                                        />

                                        <CustomHiddenField
                                            name="goal_type"
                                            value="streak"
                                            type="hidden"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomTextField
                                            label="Number Of Days"
                                            placeholder="Enter Number Of Days"
                                            name="number_of_days"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomTextField
                                            label="Coins Reward"
                                            placeholder="Enter Coins Reward"
                                            name="coins_reward"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Coins reward is required" }}
                                        />

                                        <CustomTextField
                                            label="Badge Unlock"
                                            placeholder="Upload Image"
                                            name="avatar"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextField
                                            label="Badge Lock"
                                            placeholder="Upload Image"
                                            name="avatar_lock"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextAreaField
                                            label="Description"
                                            placeholder="Enter Description"
                                            name="goal_description"
                                            classNamePro="stage-field mb-3"
                                            isRequired={false}
                                            value={methods.watch("goal_description")}
                                            onChange={(data) => methods.setValue("goal_description", data)}
                                        />

                                        <button type="submit" className="btn btn-primary mt-3">Add Badge</button>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'quest' && (
                                <div className="tab-pane active">
                                    <div className='mb-4 mt-2 px-4 bg-light rounded shadow-sm p-3'>
                                        {/* Input Fields */}
                                        <CustomTextField
                                            label="Goal Name"
                                            placeholder="Enter Goal Name"
                                            name="goal_name"
                                            type="text"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Goal name is required" }}
                                        />

                                        <CustomHiddenField
                                            name="goal_type"
                                            value="quest"
                                            type="hidden"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomSelectField
                                            label="Level"
                                            name="level"
                                            options={[
                                                { value: 'Easy', label: 'Easy' },
                                                { value: 'Intermediate', label: 'Intermediate' },
                                                { value: 'Advance', label: 'Advance' }
                                            ]}
                                            classNamePro="stage-field mb-3"
                                            validation={{ required: "Level is required" }}
                                        />

                                        <CustomTextField
                                            label="Number Of Quests"
                                            placeholder="Enter Number Of Quests"
                                            name="number_of_quests"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                        />

                                        <CustomTextField
                                            label="Coins Reward"
                                            placeholder="Enter Coins Reward"
                                            name="coins_reward"
                                            type="number"
                                            classNamePro="stage-field mb-3"
                                            isRequired={true}
                                            validation={{ required: "Coins reward is required" }}
                                        />

                                        <CustomTextField
                                            label="Badge Unlock"
                                            placeholder="Upload Image"
                                            name="avatar"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextField
                                            label="Badge Lock"
                                            placeholder="Upload Image"
                                            name="avatar_lock"
                                            type="file"
                                            classNamePro="ocean-field image-file-ocean mb-3"
                                            isRequired={true}
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomTextAreaField
                                            label="Description"
                                            placeholder="Enter Description"
                                            name="goal_description"
                                            classNamePro="stage-field mb-3"
                                            isRequired={false}
                                            value={methods.watch("goal_description")}
                                            onChange={(data) => methods.setValue("goal_description", data)}
                                        />

                                        <button type="submit" className="btn btn-primary mt-3">Add Badge</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default AddBadges;
