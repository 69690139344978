import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import { useRecoilState } from "recoil";
import { isLoaderState } from "../../store/RecoilStore";
import { apiPost } from "../../utils/Api";
import './TableComponent.css';

const TableComponent = ({ title, endPoint, headers, reloadData, searchObject, bgColor, extraParams }) => {
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [items, setItems] = useState([]);
    const [currentUrl, setCurrentUrl] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        const url = `?page=${currentPage + 1}&limit=${itemsPerPage}`;
        setCurrentUrl(url);
        getPaginatedData(url);
    }, [reloadData, currentPage]);

    const handlePageClick = (item) => {
        setCurrentPage(item.selected);
    };

    const getPaginatedData = (url) => {
        setIsLoaderInfo(true);

        const requestData = { ...searchObject, ...extraParams };

        apiPost(
            endPoint + url,
            requestData,
            (res) => {
                setItems(res?.data?.data);
                const totalItems = res?.data?.total;
                setTotalPages(Math.ceil(totalItems / itemsPerPage));
                setIsLoaderInfo(false);
            },
            (error) => {
                console.log(error);
                setIsLoaderInfo(false);
            }
        );
    };

    return (
        <>
            <div className="contianer-fluid my-dataTable">
                <div className={`table-main-header bg-primary p-2 ${bgColor}`}>
                    <h5 className="my-auto text-white p-2">{title}</h5>
                </div>

                <div className="table-responsive">
                    <DataTable
                        className=""
                        columns={headers}
                        data={items}
                        pointerOnHover
                        striped
                        dense={false}
                    />
                </div>
            </div>
            <ReactPaginate
                autoWidth
                className="pagination-custom mt-4"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={totalPages}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </>
    );
};

TableComponent.defaultProps = {
    endPoint: "",
    headers: [],
    reloadData: false,
    searchObject: {},
    extraParams: {},
};

export default TableComponent;