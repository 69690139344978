// src/components/StageForm.js
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import { apiGetAuth, apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSelectField from '../../components/globals/CustomSelectField';
import CustomTextAreaField from '../../components/globals/CustomTextAreaField';

const EditChallenge = () => {
    const { challenge_id } = useParams();
    const methods = useForm();
    const { handleSubmit, reset, setValue } = methods;
    const [submittedData, setSubmittedData] = useState(null);
    const navigate = useNavigate();
    const [oceans, setOceans] = useState([]);
    const [filteredStages, setFilteredStages] = useState([]);
    const [questData, setQuestData] = useState(null);

    const getChallengeDetails = () => {
        apiGetAuth(
            `${ENDPOINTS.AdminChallengeDetailByID}?challenge_id=${challenge_id}`,
            (res) => {
                setQuestData(res);
                setValue('title', res.title);
                setValue('description', res.description);
                setValue('level', res.level);
                setValue('time', res.time);
                setValue('reward_price', res.reward_price);
                setValue('questions_count', res.questions_count);
                setValue('ocean_id', res.ocean_id);
            },
            (err) => {
                console.log(err, "Error fetching quest details");
            }
        );
    };

    // Submit Updated Quest Data
    const onSubmit = (data) => {
        const formData = new FormData();

        formData.append('ocean_id', data.ocean_id);
        formData.append('title', data.title);
        formData.append('level', data.level);
        formData.append('time', data.time);
        formData.append('reward_price', data.reward_price);
        formData.append('questions_count', data.questions_count);
        formData.append('description', data.description);
        formData.append('status', 'locked');
        formData.append('challenge_id', challenge_id);


        apiPost(
            `${ENDPOINTS.AdminEditChallenge}`,
            formData,
            (res) => {
                setSubmittedData(data);
                reset();
                navigate(`/super-admin/all-challenges`);
            },
            (err) => {
                console.log(err, "Error updating the data");
            },
        );
    };

    // Fetch Oceans
    const getOceans = () => {
        apiGetAuth(
            ENDPOINTS.AdminOceansWithoutPaginated,
            (res) => {
                setOceans(res);
            },
            (err) => {
                console.log(err, "Error fetching oceans");
            }
        );
    };

    useEffect(() => {
        getOceans();
        getChallengeDetails();
    }, [challenge_id]);

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-wrapper bg-light p-4 rounded shadow-sm">
                <h2 className="text-center mb-4">Edit Challenge</h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* Title Field */}
                        <CustomTextField
                            label="Title"
                            placeholder="Enter Title"
                            name="title"
                            type="text"
                            classNamePro="stage-field mb-3"
                            isRequired={true}
                            validation={{ required: "Title is required" }}
                        />

                        {/* Ocean Dropdown */}
                        <CustomSelectField
                            label="Select Ocean"
                            name="ocean_id"
                            options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                        />

                        {/* Level Field */}
                        <CustomSelectField
                            label="Level"
                            name="level"
                            options={[
                                { value: 'easy', label: 'Easy' },
                                { value: 'medium', label: 'Medium' },
                                { value: 'hard', label: 'Hard' }
                            ]}
                            classNamePro="stage-field mb-3"
                            validation={{ required: 'Level is required' }}
                        />

                        {/* Time Field */}
                        <CustomTextField
                            label="Time (in minutes)"
                            placeholder="Enter Time"
                            name="time"
                            type="number"
                            classNamePro="stage-field mb-3"
                            isRequired={true}
                            validation={{
                                required: "Time is required",
                                min: {
                                    value: 1,
                                    message: "Time must be at least 1 minute",
                                },
                            }}
                        />

                        {/* Number Of Questions Field */}
                        <CustomTextField
                            label="Number Of Questions"
                            placeholder="Enter Number Of Questions"
                            name="questions_count"
                            type="number"
                            classNamePro="stage-field mb-3"
                            isRequired={true}
                            validation={{
                                required: "Number Of Questions is required",
                                min: {
                                    value: 1,
                                    message: "There must be at least 1 question",
                                },
                            }}
                        />

                        {/* Description Field */}
                        <CustomTextAreaField
                            label="Description"
                            placeholder="Enter Description"
                            name="description"
                            classNamePro="stage-field mb-3"
                            isRequired={false}
                            value={methods.watch("description")}
                            onChange={(data) => methods.setValue("description", data)}
                        />

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary w-100">
                                Update
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default EditChallenge;