import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CustomTextAreaField = ({ label, placeholder, name, classNamePro, isRequired, value, onChange }) => {
    return (
        <div className={classNamePro}>
            <label htmlFor={name}>
                {label} {isRequired && <span>*</span>}
            </label>
            <CKEditor
                editor={ClassicEditor}
                data={value}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data);
                }}
                config={{
                    placeholder: placeholder,
                }}
            />
        </div>
    );
};

export default CustomTextAreaField;
