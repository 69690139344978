import React, { useEffect, useState } from 'react';
import TableComponent from '../../components/globals/TableComponent';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate, useParams } from 'react-router-dom';
import { apiPost } from '../../utils/Api';
import { Tab, Tabs } from 'react-bootstrap';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';


function AdminStages() {
  const param = useParams();
  const ocean_id = param.ocean_id;

  const navigate = useNavigate();

  const [ocean, setOcean] = useState([]);
  const [oceans, setStages] = useState([]);
  const [quests, setQuest] = useState([]);
  const [challenges, setChallenge] = useState([]);



  const handleOnClick = async (stage_id) => {
    navigate(`/super-admin/admin-lessons/${stage_id}`, {
      state: { stage_id, ocean_id },
    });
  };

  const deleteStage = (lessonId) => {

    const payload = {
      id: lessonId,
    }
    apiPost(
      ENDPOINTS.AdminDeleteStage,
      payload,
      (res) => {
        setStages(res?.data);
        window.location.reload();
      },
      (err) => {
        console.log(err, 'Error deleting lesson');
      }
    )
  }

  const handleEditClick = async (lesson_id) => {
    navigate(`/super-admin/edit-stage/${lesson_id}`);
  };

  const handleQuestEditClick = async (quest_id) => {
    navigate(`/super-admin/edit-quest/${quest_id}`);
  };

  const deleteQuest = (questId) => {

    const payload = {
      id: questId,
    }

    apiPost(
      ENDPOINTS.AdminDeleteQuest,
      payload,
      (res) => {
        setQuest(res?.data);
        window.location.reload();
      },
      (err) => {
        console.log(err, 'Error deleting ocean');
      }
    )
  }

  const handleChallengeEditClick = async (challenge_id) => {
    navigate(`/super-admin/edit-challenge/${challenge_id}`);
  };

  const deleteChallenge = (challengeId) => {

    const payload = {
      id: challengeId,
    }

    apiPost(
      ENDPOINTS.AdminDeleteChallenge,
      payload,
      (res) => {
        setChallenge(res?.data);
        window.location.reload();
      },
      (err) => {
        console.log(err, 'Error deleting ocean');
      }
    )
  }

  useEffect(() => {
    oceanDetail();
  }, [ocean_id]);

  const oceanDetail = async () => {
    let payload = {
      ocean_id: ocean_id
    }
    apiPost(
      ENDPOINTS.AdminOceanDetailByIDPaginated,
      payload,
      (res) => {
        setOcean(res?.data);
      },
      (err) => {
        console.log("Error:", err);
      }
    );
  }

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px',
    },
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: false,
    },
    {
      name: 'Coin Cost',
      selector: (row) => row?.coin_cost,
      sortable: false,
    },
    {
      name: 'Description',
      selector: (row) => row?.description.replace(/<[^>]*>/g, ''),
      sortable: false,
    },
    {
      name: 'Ocean Name',
      selector: (row) => row?.ocean?.name,
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className='set-show-all'>
          <FaEye
            className="icon"
            onClick={() => handleOnClick(row.id)}
            title="View"
          />
          <FaEdit
            className="icon"
            onClick={() => handleEditClick(row.id)}
            title="Edit"
          />
          <FaTrash
            className="icon text-danger"
            onClick={() => {
              if (window.confirm("This stage will be removed from all associated lessons and questions. Please confirm if you want to delete it.")) {
                deleteStage(row.id);
              }
            }}
            title="Delete"
          />
        </div>
      ),
      sortable: false,
    },
  ];

  const questColumns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px',
    },
    {
      name: 'Title',
      selector: (row) => row?.title,
      sortable: false,
    },
    {
      name: 'Level',
      selector: (row) => row?.level,
      sortable: false,
    },
    {
      name: 'Time',
      selector: (row) => row?.time,
      sortable: false,
    },
    {
      name: 'Reward Price',
      selector: (row) => row?.reward_price,
      sortable: false,
    },
    {
      name: 'Number Of Questions',
      selector: (row) => row?.questions_count,
      sortable: false,
    },
    {
      name: 'Description',
      selector: (row) => row?.description.replace(/<[^>]*>/g, ''),
      sortable: false,
    },
    {
      name: 'Ocean Name',
      selector: (row) => row?.ocean?.name,
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className='set-show-all'>
          <FaEdit
            className="icon"
            onClick={() => handleQuestEditClick(row.id)}
            title="Edit"
          />
          <FaTrash
            className="icon text-danger"
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this quest?")) {
                deleteQuest(row.id);
              }
            }}
            title="Delete"
          />
        </div>
      ),
      sortable: false,
    },
  ];

  const challengeColumns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px',
    },
    {
      name: 'Title',
      selector: (row) => row?.title,
      sortable: false,
    },
    {
      name: 'Level',
      selector: (row) => row?.level,
      sortable: false,
    },
    {
      name: 'Time',
      selector: (row) => row?.time,
      sortable: false,
    },
    {
      name: 'Number Of Questions',
      selector: (row) => row?.questions_count,
      sortable: false,
    },
    {
      name: 'Description',
      selector: (row) => row?.description.replace(/<[^>]*>/g, ''),
      sortable: false,
    },
    {
      name: 'Ocean Name',
      selector: (row) => row?.ocean?.name,
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className='set-show-all'>
          <FaEdit
            className="icon"
            onClick={() => handleChallengeEditClick(row.id)}
            title="Edit"
          />
          <FaTrash
            className="icon text-danger"
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this challenge?")) {
                deleteChallenge(row.id);
              }
            }}
            title="Delete"
          />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-4 mt-2'>
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="mb-0">Ocean Details</h5>
            </div>
            <div className="card-body">
              {/* {ocean.image && (
                <img
                  src={ocean.image}
                  alt={`${ocean.name} image`}
                  className="img-fluid mb-3"
                  style={{ maxHeight: '200px', objectFit: 'cover' }}
                />
              )} */}
              <p><strong>ID:</strong> {ocean.id}</p>
              <p><strong>Name:</strong> {ocean.name}</p>
              <p><strong>Level:</strong> {ocean.level}</p>
            </div>
          </div>
        </div>
        <div className='col-lg-8 mt-2'>
          <Tabs defaultActiveKey="stages" id="ocean-tabs" className="mb-3">
            <Tab eventKey="stages" title="Stages">
              <TableComponent
                title={"Stages"}
                endPoint={ENDPOINTS.AdminStagesPaginated}
                headers={columns}
                extraParams={{ ocean_id }}
              />
            </Tab>
            <Tab eventKey="quests" title="Quests">
              <TableComponent
                title={"Quests"}
                endPoint={ENDPOINTS.AdminQuestsPaginated}
                headers={questColumns}
                extraParams={{ ocean_id }}
              />
            </Tab>
            <Tab eventKey="challenges" title="Challenges">
              <TableComponent
                title={"Challenges"}
                endPoint={ENDPOINTS.AdminChallengesPaginated}
                headers={challengeColumns}
                extraParams={{ ocean_id }}
              />
            </Tab>
          </Tabs>

        </div>
      </div>
    </div>
  );
}

export default AdminStages;
