
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_IMG_URL = process.env.REACT_APP_API_FILES_URL;
export const ENDPOINTS = {

    //User
    UserRegister: `${BASE_URL}register`,
    UserLogin: `${BASE_URL}login`,
    SendOtp: `${BASE_URL}send-password-otp`,
    veriFyOTP: `${BASE_URL}match-otp`,
    ChangePassword: `${BASE_URL}reset-password-web`,
    UpdateProfile: `${BASE_URL}update-profile`,
    GetProfile: `${BASE_URL}other-user-profile`,
    UserDetail: `${BASE_URL}user-details`,

    // Topics
    GetTopic: `${BASE_URL}topics`,
    AddTopic: `${BASE_URL}add-topic`,

    // Oceans
    GetOceans: `${BASE_URL}oceans`,
    UserSelectedOption: `${BASE_URL}user-selected-option`,

    // Stages
    GetStages: `${BASE_URL}stages`,

    // Lessons
    GetLessons: `${BASE_URL}lessons`,
    GetCoins: `${BASE_URL}coins`,
    CompleteLesson: `${BASE_URL}is-complete-lesson`,
    CompleteQuiz: `${BASE_URL}is-complete-quiz`,
    StartQuizDeductCoins: `${BASE_URL}start-quiz-deduct-coins`,

    AddQuestionAnswers: `${BASE_URL}add-question-answers`,

    // Admin API's
    AdminUsersPaginated: `${BASE_URL}users`,
    AdminOceansPaginated: `${BASE_URL}list-oceans`,
    AdminOceansWithoutPaginated: `${BASE_URL}oceans-without-pagination`,
    AdminStagesPaginated: `${BASE_URL}list-stages`,
    AdminLessonsPaginated: `${BASE_URL}list-lessons`,
    AdminQuestsPaginated: `${BASE_URL}list-quests`,
    AdminChallengesPaginated: `${BASE_URL}list-challenges`,
    AdminOceanDetailByIDPaginated: `${BASE_URL}ocean-detail-by-id`,
    AdminStageDetailByID: `${BASE_URL}stage-detail-by-id`,
    AdminLessonDetailByID: `${BASE_URL}lesson-detail-by-id`,
    AdminAddOcean: `${BASE_URL}add-ocean`,
    AdminEditOcean: `${BASE_URL}update-ocean`,
    AdminDeleteOcean: `${BASE_URL}delete-ocean`,
    AdminAddStage: `${BASE_URL}add-stage`,
    AdminEditStage: `${BASE_URL}update-stage`,
    AdminDeleteStage: `${BASE_URL}delete-stage`,
    AdminAddLesson: `${BASE_URL}add-lesson`,
    AdminEditLesson: `${BASE_URL}update-lesson`,
    AdminDeleteLesson: `${BASE_URL}delete-lesson`,
    AdminStagesWithoutPaginated: `${BASE_URL}stages-without-pagination`,
    AdminQuestsWithoutPaginated: `${BASE_URL}quests-without-pagination`,
    AdminChallengesWithoutPaginated: `${BASE_URL}challenges-without-pagination`,
    AdminAddQuest: `${BASE_URL}add-quest`,
    AdminEditQuest: `${BASE_URL}update-quest`,
    AdminDeleteQuest: `${BASE_URL}delete-quest`,
    AdminQuestDetailByID: `${BASE_URL}quest-detail-by-id`,
    AdminAddChallenge: `${BASE_URL}add-challenge`,
    AdminEditChallenge: `${BASE_URL}update-challenge`,
    AdminDeleteChallenge: `${BASE_URL}delete-challenge`,
    AdminChallengeDetailByID: `${BASE_URL}challenge-detail-by-id`,
    AdminQuestionsAnswers: `${BASE_URL}list-questions`,
    AdminQuestQuestionsAnswers: `${BASE_URL}list-quest-questions`,
    AdminChallengeQuestionsAnswers: `${BASE_URL}list-challenge-questions`,
    AdminAddQuestionsAnswers: `${BASE_URL}add-question-admin`,
    AdminEditQuestionsAnswers: `${BASE_URL}update-question-admin`,
    AdminLessonsWithoutPaginated: `${BASE_URL}lessons-without-pagination`,
    AdminQuestionDetailByID: `${BASE_URL}question-detail-by-id`,
    AdminQuestDetailByIDPaginated: `${BASE_URL}quest-detail-by-id`,
    AdminChallengeDetailByIDPaginated: `${BASE_URL}challenge-detail-by-id`,
    AdminAddQuestQuestionsAnswers: `${BASE_URL}add-quest-question`,
    AdminAddChallengeQuestionsAnswers: `${BASE_URL}add-challenge-question`,
    AdminDeleteQuestion: `${BASE_URL}delete-question-admin`,
    AdminEditQuestQuestionsAnswers: `${BASE_URL}update-quest-question-admin`,
    AdminEditChallengeQuestionsAnswers: `${BASE_URL}update-challenge-question-admin`,
    AdminUserCount: `${BASE_URL}user-count`,
    AdminBadges: `${BASE_URL}badges`,
    AdminDeleteBadge: `${BASE_URL}delete-badge`,
    AdminAddBadges: `${BASE_URL}add-badge`,
    AdminEditBadges: `${BASE_URL}edit-badge`,
    AdminBadgeById: `${BASE_URL}badge-by-id`,
    AdminUserBadges:`${BASE_URL}admin-user-badges`,
    AdminUserStages:`${BASE_URL}admin-user-stages`,
    AdminUserOceans:`${BASE_URL}admin-user-oceans`,
    AdminUserTransactions:`${BASE_URL}user-coin-transactions`,
    AdminLeaderboard:`${BASE_URL}admin-leaderboard`,

}