import React from 'react'
import TableComponent from '../../components/globals/TableComponent'
import { ENDPOINTS } from '../../utils/EndPoint'
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


function Users() {

    const navigate = useNavigate();

    const handleViewClick = async (userId) => {
        navigate(`/super-admin/user-details/${userId}`, {
          state: { userId },
        });
      };

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: false,
            width: '60px',
        },
        {
            name: 'Email',
            selector: (row) => row?.email,
            sortable: false,
        },
        {
            name: 'Country',
            selector: (row) => row?.country,
            sortable: false,
        },
        {
            name: 'Total Coins',
            selector: (row) => row?.total_coins,
            sortable: false,
        },
        {
            name: 'Actions',
            selector: (row) => (
                <div className='set-show-all'>
                    <FaEye
                        className="icon"
                        onClick={() => handleViewClick(row.id)}
                        title="View"
                    />
                </div>
            ),
            sortable: false,
        },
    ];


    return (
        <>
            <TableComponent
                title={"Users"}
                endPoint={ENDPOINTS.AdminUsersPaginated}
                headers={columns}
            />
        </>

    )
}

export default Users