import React, { useEffect, useState } from 'react';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate, useParams } from 'react-router-dom';
import { apiPost } from '../../utils/Api';
import { Accordion } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

function AdminQuests() {
  const param = useParams();
  const quest_id = param.quest_id;
  const [oceanId, setOceanId] = useState(null);

  const navigate = useNavigate();

  const [ocean, setQuest] = useState({});
  const [questions, setQuestions] = useState([]);

  const fetchQuestions = () => {
    const payload = {
      quest_id: quest_id,
      ocean_id: oceanId,
    };

    apiPost(
      ENDPOINTS.AdminQuestQuestionsAnswers,
      payload,
      (res) => {
        setQuestions(res?.data?.data || []);
        console.log(res, 'Fetched Questions');
      },
      (err) => {
        console.log(err, 'Error fetching questions');
      }
    );
  };

  const handleAddQuestionAnswersOnClick = () => {
    navigate(`/super-admin/add-quest-question-answers`, {
      state: { ocean_id: oceanId, quest_id: quest_id, },
    });
  };

  const handleEditQuestionAnswersOnClick = (question_id) => {
    navigate(`/super-admin/edit-quest-question-answers/${question_id}`, {
      state: { ocean_id: oceanId, quest_id: quest_id, },
    });
  };

  const deleteQuestion = (questionId) => {
    const payload = {
      id: questionId,
    };

    if (questions.length - 1 >= ocean.questions_count) {
      apiPost(
        ENDPOINTS.AdminDeleteQuestion,
        payload,
        (res) => {
          setQuestions(prevQuestions => prevQuestions.filter(question => question.id !== questionId));
        },
        (err) => {
          console.log(err, 'Error deleting question');
        }
      );
    } else {
      alert("You cannot delete the question because it would reduce the total number of questions below the allowed limit for this quest.");
    }
  };

  useEffect(() => {
    questDetail();
    fetchQuestions();
  }, [quest_id, oceanId]);

  const questDetail = async () => {
    let payload = {
      quest_id: quest_id,
    };
    apiPost(
      ENDPOINTS.AdminQuestDetailByIDPaginated,
      payload,
      (res) => {
        const questData = res?.data;
        setQuest(questData);
        setOceanId(questData?.ocean_id);
      },
      (err) => {
        console.log("Error:", err);
      }
    );
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-4 mt-2'>
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="mb-0">Quest Details</h5>
            </div>
            <div className="card-body">
              <p><strong>Title:</strong> {ocean?.title}</p>
              <p><strong>Level:</strong> {ocean?.level}</p>
              <p><strong>Time (seconds):</strong> {ocean?.time}</p>
              <p><strong>Reward Price:</strong> {ocean?.reward_price}</p>
              <p><strong>Number Of Questions:</strong> {ocean?.questions_count}</p>
              <p><strong>Description:</strong> {ocean?.description ? ocean.description.replace(/<[^>]*>/g, '') : ''}
              </p>
            </div>
          </div>
        </div>
        <div className='col-lg-8 mt-2'>
          <div className="container">
            <div className='d-flex justify-content-between align-items-center'>
              <h2 className="mb-4">Questions and Answers</h2>
              <button className='mb-4 btn btn-primary' onClick={handleAddQuestionAnswersOnClick}>Add Question & Answers</button>
            </div>
            <Accordion>
              {questions.map((question, index) => (
                <Accordion.Item eventKey={String(index)} key={question.id}>
                  <Accordion.Header>
                    {question.qst_no + 1}. {question.question}
                    <div className="ms-2 d-flex align-items-center">
                      <FaPencilAlt
                        className="me-3 text-primary cursor-pointer"
                        onClick={() => handleEditQuestionAnswersOnClick(question.id)}
                      />
                      <FaTrashAlt
                        className="text-danger cursor-pointer"
                        onClick={() => {
                          if (window.confirm("Are you sure you want to delete this question?")) {
                            deleteQuestion(question.id);
                          }
                        }}
                      />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h5>Answers:</h5>
                    <ul>
                      {question.answers.map((answer) => (
                        <li key={answer.id}>
                          <strong>{answer.is_correct ? 'Correct' : 'Incorrect'}:</strong>{' '}
                          {answer.answer}
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminQuests;
