export const getTokenLocal = () => {
    return localStorage.getItem("accessToken");
};

export const setTokenLocal = (data) => {
    localStorage.setItem("accessToken", data);
};


export const getUserLocal = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
};

export const setUserLocal = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
};

export const getRoleLocal = () => {
    return localStorage.getItem("role");
};

export const setRoleLocal = (role) => {
    localStorage.setItem("role", role);
};
