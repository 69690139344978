import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import { apiGetAuth, apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate } from 'react-router-dom';
import CustomSelectField from '../../components/globals/CustomSelectField';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';

const AddQuestionAnswers = () => {
    const methods = useForm({
        defaultValues: {
            questions: [{
                question: '',
                answers: [{ text: '', is_correct: false }]
            }],
            ocean_id: '',
            stage_id: '',
            lesson_id: '',
        },
    });

    const { handleSubmit, reset, watch, setValue, control } = methods;
    const { fields: questionFields, append: appendQuestion, remove: removeQuestion } = useFieldArray({
        control,
        name: 'questions',
    });

    const navigate = useNavigate();

    const { fields: answerFields, append: appendAnswer, remove: removeAnswer } = useFieldArray({
        control,
        name: `questions[${questionFields.length - 1}].answers`,
    });

    const [oceans, setOceans] = useState([]);
    const [filteredStages, setFilteredStages] = useState([]);
    const [filteredLessons, setFilteredLessons] = useState([]);
    const [questionsCount, setQuestionsCount] = useState(0);
    const [quest, setQuestDetail] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [selectedOceanId, setSelectedOceanId] = useState(null);

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('ocean_id', data.ocean_id);
        formData.append('stage_id', data.stage_id);
        formData.append('lesson_id', data.lesson_id);

        if (questions > 0) {
            data.questions.forEach((question, index) => {
                formData.append(`questions[${index}][question]`, question.question);
                question.answers.forEach((answer, answerIndex) => {
                    formData.append(`questions[${index}][answers][${answerIndex}][answer]`, answer.text);
                    formData.append(`questions[${index}][answers][${answerIndex}][is_correct]`, answer.is_correct ? 1 : 0);
                });
            });

            apiPost(
                ENDPOINTS.AdminAddQuestionsAnswers,
                formData,
                (res) => {
                    reset();
                    navigate('/super-admin/all-lessons');
                },
                (err) => {
                    console.error(err, "Error uploading the data");
                },
            );
        } else {
            if (questionsCount !== data.questions.length) {
                alert(`The number of questions must match the predefined count: ${questionsCount}`);
                return;
            } else {
                data.questions.forEach((question, index) => {
                    formData.append(`questions[${index}][question]`, question.question);
                    question.answers.forEach((answer, answerIndex) => {
                        formData.append(`questions[${index}][answers][${answerIndex}][answer]`, answer.text);
                        formData.append(`questions[${index}][answers][${answerIndex}][is_correct]`, answer.is_correct ? 1 : 0);
                    });
                });

                apiPost(
                    ENDPOINTS.AdminAddQuestionsAnswers,
                    formData,
                    (res) => {
                        reset();
                        navigate('/super-admin/all-lessons');
                    },
                    (err) => {
                        console.error(err, "Error uploading the data");
                    },
                );
            }
        };
    }

    const getOceans = () => {
        apiGetAuth(
            ENDPOINTS.AdminOceansWithoutPaginated,
            (res) => {
                setOceans(res);
            },
            (err) => {
                console.error(err, "Error fetching oceans");
            }
        );
    };

    const getStages = (oceanId) => {
        apiGetAuth(
            `${ENDPOINTS.AdminStagesWithoutPaginated}?ocean_id=${oceanId}`,
            (res) => {
                setFilteredStages(res);
                setValue("stage_id", "");
                setValue("lesson_id", "");
                setFilteredLessons([]);
            },
            (err) => {
                console.error(err, "Error fetching stages");
            }
        );
    };

    const getLessons = (oceanId, stageId) => {
        apiGetAuth(
            `${ENDPOINTS.AdminLessonsWithoutPaginated}?ocean_id=${oceanId}&stage_id=${stageId}`,
            (res) => {
                setFilteredLessons(res);
                setValue("lesson_id", "");
            },
            (err) => {
                console.error(err, "Error fetching lessons");
            }
        );
    };

    const questDetail = async (lesson_id) => {
        let payload = {
            lesson_id: lesson_id,
        };
        apiPost(
            ENDPOINTS.AdminLessonDetailByID,
            payload,
            (res) => {
                const questData = res?.data;                
                setQuestionsCount(questData?.total_questions || 0);
            },
            (err) => {
                console.log("Error:", err);
            }
        );
    };

    const fetchQuestions = (oceanId, stageId, lessonId) => {
        const payload = {
            lesson_id: lessonId,
            ocean_id: oceanId,
            stage_id: stageId,
        };

        apiPost(
            ENDPOINTS.AdminQuestionsAnswers,
            payload,
            (res) => {
                setQuestions(res?.data?.data.length || []);
            },
            (err) => {
                console.log(err, 'Error fetching questions');
            }
        );
    };

    const handleOceanChange = (selectedOption) => {
        if (selectedOption?.value) {
            setValue("ocean_id", selectedOption.value);
            setSelectedOceanId(selectedOption.value);
            getStages(selectedOption.value);
        }
    };

    const handleStageChange = (selectedOption) => {
        if (selectedOption?.value) {
            setValue("stage_id", selectedOption.value);
            getLessons(watch("ocean_id"), selectedOption.value);
        }
    };

    const handleLessonChange = (selectedOption) => {
        if (selectedOption?.value) {
            setValue("lesson_id", selectedOption.value);
            questDetail(selectedOption.value);
            setQuestDetail(selectedOption.value);
            fetchQuestions(watch("ocean_id"), watch("stage_id"), selectedOption.value);
        }
    };

    useEffect(() => {
        getOceans();
    }, []);

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-wrapper bg-light p-4 rounded shadow-sm">
                <h2 className="text-center mb-4">Add Question & Answers</h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <CustomSelectField
                            label="Select Ocean"
                            name="ocean_id"
                            options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedOption = {
                                    value: e.target.value,
                                    label: oceans.find(ocean => ocean.id === e.target.value)?.name
                                };
                                handleOceanChange(selectedOption);
                            }}
                        />

                        <CustomSelectField
                            label="Select Stage"
                            name="stage_id"
                            options={filteredStages.map(stage => ({ value: stage.id, label: stage.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedOption = {
                                    value: e.target.value,
                                    label: filteredStages.find(stage => stage.id === e.target.value)?.name
                                };
                                handleStageChange(selectedOption);
                            }}
                        />

                        <CustomSelectField
                            label="Select Lesson"
                            name="lesson_id"
                            options={filteredLessons.map(lesson => ({ value: lesson.id, label: lesson.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedOption = {
                                    value: e.target.value,
                                    label: filteredLessons.find(stage => stage.id === e.target.value)?.name
                                };
                                handleLessonChange(selectedOption);
                            }}
                        />

                        {watch("questions").map((question, index) => (
                            <div key={index}>
                                <CustomTextField
                                    label={`Question ${index + 1}`}
                                    placeholder={`Enter Question`}
                                    name={`questions[${index}].question`}
                                    type="text"
                                    classNamePro="ocean-field mb-2"
                                    isRequired={true}
                                    validation={{ required: "Question is required" }}
                                />
                                {question.answers.map((answer, answerIndex) => (
                                    <div key={answerIndex} className="d-flex align-items-center mb-2">
                                        <CustomTextField
                                            label={`Answer ${answerIndex + 1}`}
                                            placeholder={`Enter Answer ${answerIndex + 1}`}
                                            name={`questions[${index}].answers[${answerIndex}].text`}
                                            type="text"
                                            classNamePro="ocean-field me-2"
                                            isRequired={true}
                                            validation={{ required: "Answer is required" }}
                                        />
                                        <input
                                            type="radio"
                                            name={`questions[${index}].is_correct`}
                                            value={answerIndex}
                                            checked={watch(`questions[${index}].answers[answerIndex].is_correct`)}
                                            onChange={() => {
                                                question.answers.forEach((_, i) => {
                                                    setValue(`questions[${index}].answers[${i}].is_correct`, i === answerIndex);
                                                });
                                            }}
                                        />
                                        <label className="ms-2">Correct</label>

                                        <FaTrash
                                            className="ms-2 text-danger"
                                            onClick={() => removeAnswer(answerIndex)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                ))}

                                <FaPlusCircle
                                    className="text-secondary"
                                    onClick={() => appendAnswer({ text: '', is_correct: false })}
                                    style={{ cursor: 'pointer', fontSize: '1.5em' }}
                                />

                                <FaTrash
                                    className="ms-2 text-danger"
                                    onClick={() => removeQuestion(index)}
                                    style={{ cursor: 'pointer', fontSize: '1.5em' }}
                                />

                            </div>
                        ))}

                        <div
                            className="text-secondary mb-3"
                            style={{ cursor: 'pointer', fontSize: '1.5em' }}
                            onClick={() => appendQuestion({ question: '', answers: [{ text: '', is_correct: false }] })}
                        >
                            <FaPlusCircle /> Add Question
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary w-100">Submit</button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default AddQuestionAnswers;
