import React from 'react';
import { MdGpsFixed } from 'react-icons/md';
import { useFormContext, Controller } from 'react-hook-form';
import './CustomSelectField.css';

function CustomSelectField({ 
  label, 
  name, 
  options, 
  icon, 
  classNamePro, 
  showGps, 
  validation, 
  readOnly, 
  defaultValue = "",  // Set a default value as an empty string
  onChange = () => {} // Default to a no-op function
}) {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <div className={`custom-select-field ${classNamePro}`}>
      <label className='skranji-regular text-white mb-1'>{label}</label>
      <div className="custom-select-field-inner">
        <span className='color-999999'>{icon}</span>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue} // Use the provided default value
          rules={validation}
          render={({ field }) => (
            <select
              {...field}
              onChange={(e) => {
                field.onChange(e);
                onChange(e);
              }}
              className="form-control skranji-regular color-999999"
              disabled={readOnly}
            >
              <option value="" disabled selected>Select an option</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
        />
        <span className="gps-toggle color-999999">
          {showGps && <MdGpsFixed />}
        </span>
      </div>
      {errors[name] && <span className="error-message">{errors[name].message}</span>}
    </div>
  );
}

export default CustomSelectField;
