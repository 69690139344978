import React, { useEffect, useState } from 'react';
import { ENDPOINTS } from '../../utils/EndPoint';
import { apiGetAuth } from '../../utils/Api';
import TableComponentWithPagination from '../../components/globals/TableComponentWithPagination';


function AdminLeaderboard() {
  const [oceanId, setOceanId] = useState('');
  const [oceans, setOceans] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRankSuffix = (position) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const moduloTen = position % 10;
    const moduloHundred = position % 100;

    return (
      suffixes[(moduloTen <= 3 && moduloTen > 0 && moduloHundred !== 11) ? moduloTen : 0] || 'th'
    );
  };

  const fetchOceans = () => {
    apiGetAuth(
      ENDPOINTS.AdminOceansWithoutPaginated,
      (res) => {
        setOceans(res);
      },
      (err) => {
        console.log(err, "Error fetching oceans");
      }
    );
  };

  const fetchLeaderboard = (oceanId) => {
    setLoading(true);
    apiGetAuth(
      `${ENDPOINTS.AdminLeaderboard}?ocean_id=${oceanId}`,
      (res) => {
        console.log(res.data);

        setLeaderboardData(res);
        setLoading(false);
      },
      (err) => {
        console.log(err, "Error fetching leaderboard");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchOceans();
  }, []);

  useEffect(() => {
    fetchLeaderboard(oceanId);
  }, [oceanId]);

  const formatRank = (position) => {
    return `${position}${getRankSuffix(position)}`;
  };

  const leaderboardColumns = [
    { name: 'Rank', selector: (row) => formatRank(row?.position), sortable: true },
    { name: 'User Name', selector: (row) => row?.user?.name, sortable: true },
    { name: 'Ocean', selector: (row) => row?.ocean?.name, sortable: true },
    { name: 'Accuracy', selector: (row) => row?.accuracy, sortable: true },
    { name: 'Time (s)', selector: (row) => row?.time, sortable: true },
    { name: 'Score', selector: (row) => row?.score, sortable: true },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3 mt-3 me-2">
        <h1 className='ms-2'>Leaderboard</h1>
        <select
          className="form-select form-ocean-filter"
          value={oceanId}
          onChange={(e) => setOceanId(e.target.value)}
        >
          <option value="">All Oceans</option>
          {oceans.map((ocean) => (
            <option key={ocean.id} value={ocean.id}>
              {ocean.name}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableComponentWithPagination
          title={"User Quests"}
          data={leaderboardData?.data || []}
          columns={leaderboardColumns}
          paginationData={leaderboardData}
        />

      )}
    </>
  );
}

export default AdminLeaderboard;
