import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { ENDPOINTS } from '../../utils/EndPoint';
import { apiGetAuth } from '../../utils/Api';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Challenge Registration Info',
        },
    },
};

const ChallengeYearBar = () => {
    let labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [userCounts, setUserCounts] = useState(Array(12).fill(0));

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = () => {
        apiGetAuth(
            ENDPOINTS.AdminUserCount,
            (res) => {
                if (res) {
                    const currentMonth = new Date().getMonth();
                    const updatedCounts = [...userCounts];
                    updatedCounts[currentMonth] = res.challenges;
                    setUserCounts(updatedCounts);
                }
            },
            (error) => {
                console.log(error, "Challenges Year Bar");
            }
        );
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Challenges Chart',
                data: userCounts,
                backgroundColor: '#02275e',
            },
        ],
    };

    return (
        <div>
            <Bar options={options} data={data} />
        </div>
    );
}

export default ChallengeYearBar;