// src/components/StageForm.js
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import { apiGetAuth, apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSelectField from '../../components/globals/CustomSelectField';
import CustomTextAreaField from '../../components/globals/CustomTextAreaField';

const EditStage = () => {
    const methods = useForm();
    const { handleSubmit, reset, setValue } = methods;
    const [submittedData, setSubmittedData] = useState(null);
    const navigate = useNavigate();
    const [oceans, setOceans] = useState([]);
    const { stage_id } = useParams();

    const onSubmit = (data) => {
        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('coin_cost', data.coin_cost);
        formData.append('time', data.time);
        formData.append('description', data.description);
        formData.append('stage_id', stage_id);
        formData.append('ocean_id', data.ocean_id);

        apiPost(
            `${ENDPOINTS.AdminEditStage}`,
            formData,
            (res) => {
                setSubmittedData(data);
                reset();
                navigate(`/super-admin/all-stages`);
            },
            (err) => {
                console.log(err, "Error updating the data");
            },
        );
    };

    const getStageDetails = () => {
        let payload = {
            stage_id: stage_id,
        }
        apiPost(
            ENDPOINTS.AdminStageDetailByID,
            payload,
            (res) => {
                let data = res?.data;
                setValue('name', data.name);
                setValue('coin_cost', data.coin_cost);
                setValue('time', data.time);
                setValue('description', data.description);
                setValue('ocean_id', data.ocean_id);
            },
            (err) => {
                console.log(err, "Error fetching stage details");
            }
        );
    };

    const getOceans = () => {
        apiGetAuth(
            ENDPOINTS.AdminOceansWithoutPaginated,
            (res) => {
                setOceans(res);
            },
            (err) => {
                console.log(err, "Error fetching oceans");
            }
        );
    };

    useEffect(() => {
        getStageDetails();
        getOceans();
    }, [stage_id]);

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-wrapper bg-light p-4 rounded shadow-sm">
                <h2 className="text-center mb-4">Edit Stage</h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* Name Field */}
                        <CustomTextField
                            label="Stage Name"
                            placeholder="Enter Stage Name"
                            name="name"
                            type="text"
                            classNamePro="stage-field mb-3"
                            isRequired={true}
                            validation={{
                                required: "Stage name is required",
                            }}
                        />

                        {/* Ocean Dropdown */}
                        <CustomSelectField
                            label="Select Ocean"
                            name="ocean_id"
                            options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                        />

                        {/* Coin Cost Field */}
                        <CustomTextField
                            label="Coin Cost"
                            placeholder="Enter Coin Cost"
                            name="coin_cost"
                            type="number"
                            classNamePro="stage-field mb-3"
                            isRequired={true}
                            validation={{
                                required: "Coin cost is required",
                                min: {
                                    value: 0,
                                    message: "Coin cost must be at least 0",
                                },
                            }}
                        />

                        <CustomTextAreaField
                            label="Description"
                            placeholder="Enter Description"
                            name="description"
                            classNamePro="stage-field mb-3"
                            isRequired={false}
                            value={methods.watch("description")}
                            onChange={(data) => methods.setValue("description", data)}
                        />

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary w-100">
                                Update
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default EditStage;
