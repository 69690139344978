import { Outlet, Navigate } from 'react-router-dom'
import { getRoleLocal, getTokenLocal } from '../../utils/Session';

const SuperAdminRoutes = () => {
    let auth = getTokenLocal();
    let role = getRoleLocal();


    if(!auth){
       return(<Navigate to="/login"/>)
    }
    if(!role){
        return(<Navigate to="/login"/>)
     }

     if(role !="admin"){
        return(<Navigate to="/login"/>)
     }

    return(
        <Outlet/>
    )
}

export default SuperAdminRoutes