import React from 'react';
import './CustomWelcomeBox.css';
import shakehand from '../../assets/images/shake-hand.gif';

function CustomWelcomeBox() {
    return (
        <div className='d-flex justify-content-center align-items-center custom-welcome-box-class'>
            <div className='bg-primary custom-welcome-inner text-inner'>
                <h4 className='text-warning text-center'>My name is Z! Welcome to SharkSkillz, the place to learn Business,
                    Finance, Crypto, A.I. and Life skillz. Dive into our Oceans, Challenges and Quests and learn
                    the skillz that matter.</h4>
            </div>
            <img src={shakehand} className='shakehand' />
        </div>
    )
}

export default CustomWelcomeBox