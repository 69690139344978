import React, { useState } from 'react';
import TableComponent from '../../components/globals/TableComponent';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate } from 'react-router-dom';
import { apiPost } from '../../utils/Api';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';


function AllStages() {

  const navigate = useNavigate();
  const [oceans, setStages] = useState([]);


  const handleOnClick = async (stage_id, ocean_id) => {
    navigate(`/super-admin/admin-lessons/${stage_id}`, {
      state: { stage_id, ocean_id },
    });
  };

  const deleteStage = (stageId) => {

    const payload = {
      id: stageId,
    }
    apiPost(
      ENDPOINTS.AdminDeleteStage,
      payload,
      (res) => {
        setStages(res?.data);
        window.location.reload();
      },
      (err) => {
        console.log(err, 'Error deleting ocean');
      }
    )
  }

  const handleAddClick = () => {
    navigate('/super-admin/add-stage');
  };

  const handleEditClick = async (stage_id) => {
    navigate(`/super-admin/edit-stage/${stage_id}`);
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px',
    },
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: false,
    },
    {
      name: 'Coin Cost',
      selector: (row) => row?.coin_cost,
      sortable: false,
    },
    {
      name: 'Description',
      selector: (row) => {
        const cleanDescription = row?.description.replace(/<[^>]*>/g, '');
        const maxLength = 100;
        return cleanDescription.length > maxLength
          ? cleanDescription.slice(0, maxLength) + '...'
          : cleanDescription;
      },
      sortable: false,
    },
    {
      name: 'Ocean Name',
      selector: (row) => row?.ocean?.name,
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className='set-show-all'>
          <FaEye
            className="icon me-2"
            onClick={() => handleOnClick(row?.id, row?.ocean_id)}
            title="View"
          />
          <FaEdit
            className="icon me-2"
            onClick={() => handleEditClick(row.id)}
            title="Edit"
          />
          <FaTrash
            className="icon text-danger"
            onClick={() => {
              if (window.confirm("This stage will be removed from all associated lessons and questions. Please confirm if you want to delete it.")) {
                deleteStage(row.id);
              }
            }}
            title="Delete"
          />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3 mt-3 me-2">
        <h1 className='ms-2'>Stages</h1>
        <button
          className="btn btn-primary"
          onClick={handleAddClick}
        >
          Add Stage
        </button>
      </div>
      <TableComponent
        title={"Stages"}
        endPoint={ENDPOINTS.AdminStagesPaginated}
        headers={columns}
      />
    </>

  );
}

export default AllStages;
