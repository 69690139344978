import React from 'react';
import TableComponent from '../../components/globals/TableComponent';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';


function AdminLessons() {
  const location = useLocation();
  const ocean_id = location.state?.ocean_id;
  const stage_id = location.state?.stage_id;
  
  const navigate = useNavigate();

  const handleQuestionOnClick = async (lesson_id) => {
    navigate(`/super-admin/view-question-answers/${lesson_id}`, {
      state: { ocean_id: ocean_id, stage_id: stage_id, lesson_id: lesson_id }
    });
  };

  const handleEditClick = async (lesson_id) => {
    navigate(`/super-admin/edit-lesson/${lesson_id}`);
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
      width: '60px',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Number Of Questions',
      selector: (row) => row.total_questions,
      sortable: true,
    },
    {
      name: 'Stage Name',
      selector: (row) => row.stage?.name,
      sortable: true,
    },
    {
      name: 'Ocean Name',
      selector: (row) => row.ocean?.name,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => (row.description.length > 250 ? row.description.replace(/<[^>]*>/g, '').substring(0, 250) + '...' : row.description.replace(/<[^>]*>/g, '')),
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className='set-show-all'>
          <FaEye
            className="icon me-2"
            onClick={() => handleQuestionOnClick(row.id)}
            title="View"
          />
          <FaEdit
            className="icon me-2"
            onClick={() => handleEditClick(row.id)}
            title="Edit"
          />
          <FaTrash
            className="icon text-danger"
            // onClick={() => {
            //   if (window.confirm("Are you sure you want to delete this item?")) {
            //     deleteItem(row.id);
            //   }
            // }}
            title="Delete"
          />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <TableComponent
      title={"Lessons"}
      endPoint={ENDPOINTS.AdminLessonsPaginated}
      headers={columns}
      searchObject={{ ocean_id, stage_id }}
    />
  );
}

export default AdminLessons;