import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { getRoleLocal, getTokenLocal, getUserLocal } from '../../utils/Session';

const AuthRoutes = () => {

  const navigate = useNavigate();

  let auth = getTokenLocal();
  let user = getUserLocal();
  let role = getRoleLocal();

  if (!auth || user.email_verified_at) {
    return <Outlet />
  }

  if (role == "user") {
    return <Navigate to="/admin/ocean" />
  }

  if (role == "admin") {
    return <Navigate to="/super-admin/dashboard" />
  }

}

export default AuthRoutes;