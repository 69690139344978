// src/components/SkillForm.js
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import { apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { FaUserAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import CustomSelectField from '../../components/globals/CustomSelectField';

const AddOcean = () => {
    const methods = useForm();
    const { handleSubmit, reset } = methods;
    const [submittedData, setSubmittedData] = useState(null);
    const navigate = useNavigate();


    const onSubmit = (data) => {

        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('level', data.level);

        if (data.image && data.image[0]) {
            formData.append('image', data.image[0]);
        }

        apiPost(
            ENDPOINTS.AdminAddOcean,
            formData,
            (res) => {
                setSubmittedData(data);
                reset();
                navigate(`/super-admin/oceans`);
            },
            (err) => {
                console.log(err, "Error uploading the file");
            },
        );
    };

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-wrapper bg-light p-4 rounded shadow-sm">
                <h2 className="text-center mb-4">Add Ocean</h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CustomTextField
                            icon={<FaUserAlt />}
                            label="Name"
                            placeholder="Enter Name"
                            name="name"
                            type="text"
                            classNamePro="ocean-field mb-3"
                            isRequired={true}
                            validation={{
                                required: "This field is required",
                            }}
                        />

                        <CustomTextField
                            label="Image"
                            placeholder="Upload Image"
                            name="image"
                            type="file"
                            classNamePro="ocean-field image-file-ocean mb-3"
                            isRequired={true}
                            validation={{
                                required: "This field is required",
                            }}
                        />

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary w-100">
                                Submit
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default AddOcean;
