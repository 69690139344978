import React, { useState } from 'react'
import TableComponent from '../../components/globals/TableComponent'
import { ENDPOINTS } from '../../utils/EndPoint'
import { useNavigate } from 'react-router-dom';
import { apiPost } from '../../utils/Api';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';


function Oceans() {

    const navigate = useNavigate();
    const [oceans, setOceans] = useState([]);


    const handleViewClick = async (ocean_id) => {
        navigate(`/super-admin/admin-stages/${ocean_id}`, {
            state: { ocean_id },
        });
    };

    const deleteOcean = (oceanId) => {

        const payload = {
            id: oceanId,
        }
        apiPost(
            ENDPOINTS.AdminDeleteOcean,
            payload,
            (res) => {
                setOceans(res?.data);
                window.location.reload();
            },
            (err) => {
                console.log(err, 'Error deleting ocean');
            }
        )
    }

    const handleAddClick = () => {
        navigate('/super-admin/add-ocean');
    };

    const handleEditClick = async (ocean_id) => {
        navigate(`/super-admin/edit-ocean/${ocean_id}`);
    };

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: false,
            width: '60px',
        },
        {
            name: 'Name',
            selector: (row) => row?.name,
            sortable: false,
        },
        {
            name: 'Image',
            selector: (row) => (
                <img
                    src={`${process.env.REACT_APP_BASE_URL}/${row?.image}`}
                    alt={`${row?.name}`}
                    style={{ width: '50px', height: '50px', objectFit: 'none', borderRadius: '5px' }}
                />
            ),
            sortable: false,
        },
        {
            name: 'Actions',
            selector: (row) => (
                <div className='set-show-all'>
                    <FaEye
                        className="icon me-2"
                        onClick={() => handleViewClick(row.id)}
                        title="View"
                    />
                    <FaEdit
                        className="icon me-2"
                        onClick={() => handleEditClick(row.id)}
                        title="Edit"
                    />
                    <FaTrash
                        className="icon text-danger"
                        onClick={() => {
                            if (window.confirm("This ocean will be removed from all associated stages, lessons, quests, and challenges. Please confirm if you want to delete it.")) {
                                deleteOcean(row.id);
                            }
                        }}
                        title="Delete"
                    />
                </div>
            ),
            sortable: false,
        },
    ];

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 me-2">
                <h1 className='ms-2'>Oceans</h1>
                <button
                    className="btn btn-primary"
                    onClick={handleAddClick}
                >
                    Add Ocean
                </button>
            </div>
            <TableComponent
                title={"Oceans"}
                endPoint={ENDPOINTS.AdminOceansPaginated}
                headers={columns}
            />
        </>

    )
}

export default Oceans