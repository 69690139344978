import React from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import './TableComponent.css';

const TableComponentWithPagination = ({ title, data, columns, paginationData, bgColor }) => {
    const { total, per_page, current_page } = paginationData || {};

    const handlePageClick = (page) => {
        console.log(`Page clicked: ${page.selected + 1}`);
    };

    return (
        <>
            <div className="contianer-fluid my-dataTable">
                <div className={`table-main-header bg-primary p-2 ${bgColor}`}>
                    <h5 className="my-auto text-white p-2">{title}</h5>
                </div>

                <div className="table-responsive">
                    <DataTable
                        columns={columns}
                        data={data}
                        pointerOnHover
                        striped
                        dense={false}
                    />
                </div>

            </div>

            {paginationData && (
                <ReactPaginate
                    className="pagination-custom mt-4"
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={Math.ceil(total / per_page)}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    forcePage={current_page - 1}
                />
            )}
        </>
    );
};

export default TableComponentWithPagination;
